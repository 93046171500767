import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import TextEditor from "../../Components/TextEditor/TextEditor";
import CodeTabs from "../../Components/CodeTabs/CodeTabs";
import { GamesInfo } from "../../Constants/Games";
import Building from "../Building/Building";
import NotFound from "../NotFound/NotFound";
import Loading from "../../Components/Loading/Loading";
import ErrorPage from "../../Pages/Error/Error";
import JSZip from "jszip";

const Editor = ({ user, name, icon, setReload, models }) => {
  const { gameName, modelId } = useParams();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [dividerPosition, setDividerPosition] = useState(40);
  const [model, setModel] = useState(null);
  const [code, setCode] = useState("");

  const mql = window.matchMedia("(max-width: 750px)");
  mql.addEventListener("change", (e) => {
    setMobileView(e.matches);
  });
  const [mobileView, setMobileView] = useState(mql.matches);

  const handleMouseDown = (e) => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    document.body.classList.add("select-none");
  };

  const handleMouseMove = (e) => {
    const newDividerPosition = (e.clientX / window.innerWidth) * 100;
    if (newDividerPosition > 20 && newDividerPosition < 90) {
      setDividerPosition(newDividerPosition);
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
    document.body.classList.remove("select-none");
  };

  useEffect(() => {
    const getModelInfo = async () => {
      setLoading(true);
      try {
        const info_res = await fetch(
          process.env.REACT_APP_endpointURL + "/model/content",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              game: gameName,
              modelId: modelId,
            }),
          }
        );
        if (!info_res.ok) {
          throw new Error(`HTTP error! status: ${info_res.status}`);
        }
        const info = await info_res.json();
        if (info.uid !== user.uid) {
          throw new Error(`HTTP error! status: ${info_res.status}`);
        }
        setModel(info);
        const zip = new JSZip();
        const contents = await zip.loadAsync(
          Uint8Array.from(atob(info.files), (char) => char.charCodeAt(0))
        );
        const code = await contents.file("player.py").async("string");
        setCode(code);
      } catch (error) {
        setError(true);
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    };

    if (modelId) {
      getModelInfo();
    }
  }, [modelId]);

  if (error) {
    return <ErrorPage />;
  }

  if (!(gameName.toLowerCase() in GamesInfo)) {
    return <NotFound />;
  }

  if (!GamesInfo[gameName.toLowerCase()].active) {
    return <Building />;
  }

  if (loading) {
    return (
      <div className="h-screen mt-64">
        <Loading />
      </div>
    );
  }

  return (
    <div className={mobileView ? "flex flex-col gap-8" : "flex"}>
      <CodeTabs
        width={mobileView ? "100vw" : `${dividerPosition}vw`}
        game={gameName.toLowerCase()}
        models={models}
        user={user}
      />
      {!mobileView && (
        <div
          className="w-4 cursor-col-resize bg-surface-light dark:bg-surface-dark"
          onMouseDown={handleMouseDown}
        ></div>
      )}
      <TextEditor
        width={mobileView ? "100vw" : `${100 - dividerPosition}vw`}
        user={user}
        game={gameName.toLowerCase()}
        name={name}
        icon={icon}
        setReload={setReload}
        model={model}
        modelCode={code}
        mobileView={mobileView}
      />
    </div>
  );
};

export default Editor;
