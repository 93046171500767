import React, { useState } from "react";
import Editor from "@monaco-editor/react";
import { GamesInfo } from "../../Constants/Games";
import SubmitModal from "../SubmitModal/SubmitModal";
import PrimaryButton from "../Buttons/PrimaryButton";

function TextEditor({
  user,
  width,
  game,
  setReload,
  model = null,
  modelCode,
  mobileView,
}) {
  const editingModel = model != null && model.files != null;
  const isDarkMode = document.documentElement.classList.contains("dark");

  const [activeTab, setActiveTab] = useState("editor");

  const initialFiles = {
    name: "player.py",
    defaultLanguage: "python",
    default: GamesInfo[game].starterCode,
  };

  const [gameCode, setGameCode] = useState(
    editingModel ? modelCode : initialFiles.default
  );
  const [submitOpen, setSubmitOpen] = useState(false);
  const [weightsFile, setWeightsFile] = useState(null);

  const handleSubmitClose = () => {
    setSubmitOpen(false);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setWeightsFile(selectedFile);
    }
  };

  return (
    <div className="flex flex-col h-full pt-2">
      {/* Tab buttons */}
      <div className="flex space-x-2 border-b border-gray-200 dark:border-gray-700">
        <button
          className={`px-4 py-2 text-sm font-medium transition-colors duration-200 ease-in-out rounded-t-md ${
            activeTab === "editor"
              ? "bg-white dark:bg-gray-800 text-black dark:text-white border-b-2 border-primary"
              : "bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-400"
          }`}
          onClick={() => setActiveTab("editor")}
        >
          Editor
        </button>
        <button
          className={`px-4 py-2 text-sm font-medium transition-colors duration-200 ease-in-out rounded-t-md ${
            activeTab === "source"
              ? "bg-white dark:bg-gray-800 text-black dark:text-white border-b-2 border-primary"
              : "bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-400"
          }`}
          onClick={() => setActiveTab("source")}
        >
          Game Source Code
        </button>
      </div>

      {/* Editor view */}
      {activeTab === "editor" && (
        <div className="editor-wrapper flex-1">
          <Editor
            lineNumbers="off"
            glyphMargin={false}
            folding={false}
            width={width}
            height="calc(100vh - 100px - 110px)"
            path={initialFiles.name}
            theme={isDarkMode ? "vs-dark" : "vs-light"}
            defaultLanguage={initialFiles.defaultLanguage}
            defaultValue={gameCode}
            onChange={(value) => {
              setGameCode(value);
            }}
            options={{
              minimap: {
                enabled: false,
              },
              fontSize: 16,
              scrollBeyondLastLine: false,
            }}
          />

          <div
            className={
              mobileView
                ? "flex flex-col items-center justify-center gap-4 mb-2 dark:text-white"
                : "flex flex-row items-center justify-between p-4 mt-2 dark:text-white"
            }
          >
            <div>
              <label>
                <p>Upload Model Weights (.pth only)</p>
                <input type="file" onChange={handleFileChange} accept=".pth" />
              </label>
            </div>
            <div>
              <PrimaryButton
                onClick={() => {
                  setSubmitOpen(true);
                }}
                children={editingModel ? "Save" : "Submit"}
              />
            </div>
          </div>
        </div>
      )}

      {/* Source code view */}
      {activeTab === "source" && (
        <div className="editor-wrapper flex-1">
          <Editor
            lineNumbers="off"
            glyphMargin={false}
            folding={false}
            width={width}
            height="calc(100vh - 81px - 110px)"
            path="game.py"
            theme={isDarkMode ? "vs-dark" : "vs-light"}
            defaultLanguage="python"
            defaultValue={GamesInfo[game].gameCode}
            options={{
              minimap: {
                enabled: false,
              },
              fontSize: 16,
              readOnly: true,
              scrollBeyondLastLine: false,
            }}
          />
        </div>
      )}

      {submitOpen && (
        <SubmitModal
          user={user}
          game={game}
          gameCode={gameCode}
          modelWeights={weightsFile}
          setReload={setReload}
          backToCode={handleSubmitClose}
          isDarkMode={isDarkMode}
          modelName={editingModel ? model.name : ""}
          modelIdGiven={editingModel ? model.modelId : ""}
        />
      )}
    </div>
  );
}

export default TextEditor;
