export const emojiMap = {
  1: "😂", // Face with Tears of Joy
  2: "🤣", // Rolling on the Floor Laughing
  3: "😹", // Cat with Tears of Joy
  4: "😆", // Grinning Squinting Face
  5: "😜", // Winking Face with Tongue
  6: "😝", // Squinting Face with Tongue
  7: "😏", // Smirking Face
  8: "🤪", // Zany Face
  9: "🙃", // Upside-Down Face
  10: "😬", // Grimacing Face
  11: "🥳", // Party Face
  12: "🤩", // Star-Struck
  13: "🤭", // Face with Hand Over Mouth
  14: "😳", // Flushed Face
  15: "🥸", // Disguised Face
  16: "😈", // Smiling Face with Horns
  17: "👻", // Ghost
  18: "💩", // Pile of Poo
  19: "🍳", // Eggs
  20: "🦙", // Llama
  21: "😺", // Grinning Cat Face
  22: "🕺", // Man Dancing
  23: "💃", // Woman Dancing
  24: "👀", // Eyes
  25: "🦄", // Unicorn
  26: "🤖", // Robot Face
  27: "🤯", // Exploding Head
  28: "😵‍💫", // Dizzy Face
  29: "😬", // Grimacing Face
  30: "😸", // Grinning Cat Face with Smiling Eyes
  31: "🤡", // Clown Face
  32: "🙈", // See-No-Evil Monkey
  33: "🙉", // Hear-No-Evil Monkey
  34: "🙊", // Speak-No-Evil Monkey
  35: "💃", // Woman Dancing
  36: "🎉", // Party Popper
  37: "🦸", // Superhero
  38: "🦹", // Supervillain
  39: "🍕", // Pizza
  40: "🍔", // Hamburger
  41: "🍟", // French Fries
  42: "🌭", // Hot Dog
  43: "🍣", // Sushi
  44: "🥨", // Pretzel
  45: "🥙", // Stuffed Flatbread
  46: "🎂", // Birthday Cake
  47: "🍰", // Shortcake
  48: "🧁", // Cupcake
  49: "🧊", // Ice Cube
  50: "🥤", // Cup with Straw
  51: "🍹", // Tropical Drink
  52: "🍾", // Bottle with Popping Cork
  53: "🥂", // Clinking Glasses
  54: "🍷", // Wine Glass
  55: "🍺", // Beer Mug
  56: "🥃", // Tumbler Glass
  57: "💃", // Woman Dancing
  58: "🕺", // Man Dancing
  59: "🏃‍♂️", // Man Running
  60: "🏃‍♀️", // Woman Running
  61: "🚀", // Rocket
  62: "🌈", // Rainbow
  63: "💖", // Sparkling Heart
  64: "💔", // Broken Heart
  65: "😍", // Smiling Face with Heart-Eyes
  66: "🥰", // Smiling Face with Hearts
  67: "😘", // Face Blowing a Kiss
  68: "😚", // Kissing Face with Closed Eyes
  69: "😋", // Face Savoring Food
  70: "😏", // Smirking Face
  71: "😜", // Winking Face with Tongue
  72: "😝", // Squinting Face with Tongue
  73: "🤑", // Money-Mouth Face
  74: "😎", // Smiling Face with Sunglasses
  75: "🤓", // Nerd Face
  76: "😛", // Face with Tongue
  77: "😱", // Face Screaming in Fear
  78: "😲", // Astonished Face
  79: "😵", // Dizzy Face
  80: "😤", // Face with Steam from Nose
  81: "🤭", // Face with Hand Over Mouth
  82: "😬", // Grimacing Face
  83: "😶", // Face Without Mouth
  84: "😏", // Smirking Face
  85: "😈", // Smiling Face with Horns
  86: "😹", // Cat with Tears of Joy
  87: "👾", // Alien Monster
  88: "💩", // Pile of Poo
  89: "👻", // Ghost
  90: "🐸", // Frog Face
  91: "🦙", // Llama
  92: "😺", // Grinning Cat Face
  93: "👨‍🎤", // Man Singer
  94: "👩‍🎤", // Woman Singer
  95: "🐶", // Dog Face
  96: "🐱", // Cat Face
  97: "🐹", // Hamster
  98: "🐰", // Rabbit Face
  99: "🫥", // Dotted Face
  100: "🦄", // Unicorn
};
