import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Pinata from "../../Images/Logos/pinata.webp";
import { GamesInfo } from "../../Constants/Games";
import ProtectedRoute from "../../protectedRoute";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";

const PinataInstructions = ({ user }) => {
  return (
    <div className="flex flex-col gap-2 p-2">
      <h2 className="flex justify-center text-xl font-bold mb-6">
        Follow the steps below to enter this contest
      </h2>
      <ul className="list-inside space-y-4 text-gray-800 dark:text-gray-300">
        <li>
          <span className="font-semibold text-yellow-500">
            1. Prepare Your Chess Model:
          </span>{" "}
          Navigate to the 'Games' page and click on 'Chess' to view the starter
          code. Use this to create your model. To assess your model, submit it
          and watch how it compares to other brAIn rot chess models on the
          'Battle' page.
        </li>
        <li>
          <span className="font-semibold text-yellow-500">
            2. Create a Pinata Account:
          </span>{" "}
          If you don’t have one, create an account on{" "}
          <a
            href="https://pinata.cloud"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 dark:text-blue-400 font-semibold"
          >
            Pinata
          </a>
          .
        </li>
        <li>
          <span className="font-semibold text-yellow-500">
            3. Upload Files:
          </span>{" "}
          Log in to Pinata, click "Upload", and select your player.py file. If
          your code uses model weights, create a .pth file named
          model_weights.pth and upload it as well. Make sure your code points to
          the correct file name for the weights.
        </li>
        <li>
          <span className="font-semibold text-yellow-500">
            4. Copy the CID:
          </span>{" "}
          After uploading, copy the unique Content Identifiers (CIDs) generated
          for your files.
        </li>
        <li>
          <span className="font-semibold text-yellow-500">5. Submit CIDs:</span>{" "}
          Submit your CIDs below by November 23, 11:59 PM CST. You can resubmit
          as many times as you want before the deadline.
        </li>
      </ul>
      <div className="text-center pt-4">
        If you are experiencing any issues, please report them{" "}
        <a
          href="https://discord.gg/ffwyjfKR"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 dark:text-blue-400 font-semibold"
        >
          here
        </a>
        .
      </div>
      <div className="p-4">
        <PinataSubmission user={user} />
      </div>
    </div>
  );
};

function PinataSubmission({ user }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    name: "",
    playerCID: "",
    weightsCID: "",
  });
  const [errors, setErrors] = useState({});
  const [canSubmit, setCanSubmit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Function to toggle the modal
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // Update canSubmit based on errors and required fields
  useEffect(() => {
    const hasErrors = Object.values(errors).some((error) => error);
    const hasEmptyRequiredFields =
      !formData.fullName || !formData.name || !formData.playerCID;

    setCanSubmit(!hasErrors && !hasEmptyRequiredFields);
  }, [errors, formData]);

  // Handle input change and validation
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Update form data
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Validate fields
    if (name === "fullName" && value.length > 30) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        fullName: "Full name must be less than 30 characters.",
      }));
    } else if (name === "name" && value.length > 10) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: "Model name must be 10 characters or fewer.",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    try {
      const token = await user.getIdToken();
      const res = await fetch(
        process.env.REACT_APP_endpointURL + "/pinata/submit",
        {
          method: "POST",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }

      setIsModalOpen(false);
    } catch (error) {
      console.error(error);
      alert("An error occurred. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex items-center justify-center">
      {/* Button to Open Modal */}
      <PrimaryButton onClick={toggleModal} children={"Submit"} />

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-100 bg-opacity-90 dark:bg-black dark:bg-opacity-70 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-gray-800 dark:text-white rounded-lg shadow-lg p-6 w-full max-w-md relative">
            {/* Close Button */}
            <button
              onClick={toggleModal}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
            >
              &times;
            </button>

            {/* Modal Header */}
            <h2 className="text-2xl font-semibold mb-4">Submit Model</h2>

            {/* Modal Form */}
            <form className="space-y-4" onSubmit={handleSubmit}>
              {/* Full Name */}
              <div>
                <label htmlFor="fullName" className="block text-sm font-medium">
                  Full Name
                </label>
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleInputChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm dark:bg-gray-700 dark:text-white"
                  placeholder="Enter your name"
                  required
                />
                {errors.fullName && (
                  <p className="text-red-500 text-sm">{errors.fullName}</p>
                )}
              </div>

              {/* Model Name */}
              <div>
                <label htmlFor="name" className="block text-sm font-medium">
                  Model Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm dark:bg-gray-700 dark:text-white"
                  placeholder="Enter model name"
                  required
                />
                {errors.name && (
                  <p className="text-red-500 text-sm">{errors.name}</p>
                )}
              </div>

              {/* Player CID */}
              <div>
                <label
                  htmlFor="playerCID"
                  className="block text-sm font-medium"
                >
                  Player CID
                </label>
                <input
                  type="text"
                  id="playerCID"
                  name="playerCID"
                  value={formData.playerCID}
                  onChange={handleInputChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm dark:bg-gray-700 dark:text-white"
                  placeholder="Enter player CID"
                  required
                />
              </div>

              {/* Weights CID */}
              <div>
                <label
                  htmlFor="weightsCID"
                  className="block text-sm font-medium"
                >
                  Model Weights CID (Optional)
                </label>
                <input
                  type="text"
                  id="weightsCID"
                  name="weightsCID"
                  value={formData.weightsCID}
                  onChange={handleInputChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm dark:bg-gray-700 dark:text-white"
                  placeholder="Enter weights CID"
                />
              </div>

              {/* Submit Button */}
              <div className="mt-6">
                <PrimaryButton
                  children={isSubmitting ? "Submitting..." : "Submit"}
                  disabled={isSubmitting || !canSubmit}
                />
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

const PinataDetails = () => {
  return (
    <div className="flex flex-col gap-2">
      <div className="bg-gray-100 text-gray-900 p-8 rounded-lg shadow-lg dark:bg-gray-900 dark:text-white">
        <h2 className="text-3xl font-bold text-center mb-6">
          Build a Grandmaster Chess Bot
        </h2>
        <p className="text-center text-lg mb-4">
          Sponsored by{" "}
          <a
            href="https://pinata.cloud"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 dark:text-blue-400 font-semibold"
          >
            Pinata
          </a>
        </p>

        <p className="text-gray-700 mb-6 dark:text-gray-300">
          <span className="font-bold text-pink-500">brAIn rot</span> is thrilled
          to announce our first company-sponsored contest, a week-long battle of
          chess bots happening{" "}
          <span className="font-semibold text-pink-500 dark:text-blue-400">
            November 17 - November 23
          </span>
          ! This is your chance to develop and showcase your ultimate
          chess-playing algorithm in a head-to-head showdown.
        </p>

        <p className="text-gray-700 mb-6 dark:text-gray-300">
          With{" "}
          <a
            href="https://pinata.cloud"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 dark:text-blue-400 font-semibold"
          >
            Pinata
          </a>{" "}
          as our proud sponsor, we’ve made uploading your bot’s code easier and
          more secure than ever. Pinata’s decentralized file storage will host
          your code and you will be able to watch your bots play each other
          here.
        </p>

        <div className="text-center mb-4">
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            Interested in joining the contest or need help with troubleshooting?
          </p>
          <a
            href="https://discord.gg/ffwyjfKR"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block bg-[#5865F2] hover:bg-[#4752c4] text-white font-semibold px-6 py-3 rounded-md transition-colors duration-200"
          >
            Join our Discord
          </a>
        </div>

        <div className="bg-gray-200 rounded-lg p-4 mb-6 dark:bg-gray-800">
          <h3 className="text-2xl font-semibold mb-3 dark:text-gray-100">
            Here’s What You Can Expect:
          </h3>
          <ul className="list-disc list-inside space-y-3 text-gray-800 dark:text-gray-300">
            <li>
              <span className="font-semibold text-yellow-500 dark:text-yellow-400">
                Build & Upload to Pinata:
              </span>{" "}
              Design a chess bot, upload it to Pinata, and get a feel for fast,
              secure decentralized storage.
            </li>
            <li>
              <span className="font-semibold text-yellow-500 dark:text-yellow-400">
                Round-Robin Tournament:
              </span>{" "}
              At the end of the week, every bot will compete in a round-robin
              tournament. You will be able to watch all the rounds and see what
              your bot does well.
            </li>
            <li>
              <span className="font-semibold text-yellow-500 dark:text-yellow-400">
                Cash Prizes:
              </span>{" "}
              The top 3 bots will win cash prizes and gain recognition as the
              first winners on brAIn rot's company sponsored contests!
            </li>
          </ul>
        </div>

        <p className="text-gray-700 mb-6 dark:text-gray-300">
          Join us in making our first sponsored contest unforgettable!
          Submissions open Sunday November 17th at 8:00 AM CST and are due{" "}
          <span className="font-bold text-pink-500">
            Saturday November 23rd at 11:59 PM CST
          </span>
          .
        </p>
        <div className="flex flex-col text-center p-2 mt-8">
          <div className="text-4xl font-semibold text-onSurface-light dark:text-onSurface-dark">
            Prizes
          </div>
          <div className="flex flex-col sm:flex-row justify-between w-full mt-6 pb-16 gap-4 text-onSurface-light">
            <div className="flex flex-col w-full items-center bg-yellow-200 p-6 rounded-lg shadow-md">
              <div className="text-2xl font-semibold">1st Place</div>
              <div className="mt-4 text-xl">$1,000</div>
            </div>
            <div className="flex flex-col w-full items-center bg-gray-200 p-6 rounded-lg shadow-md">
              <div className="text-2xl font-semibold">2nd Place</div>
              <div className="mt-4 text-xl">$750</div>
            </div>
            <div className="flex flex-col w-full items-center bg-orange-200 p-6 rounded-lg shadow-md">
              <div className="text-2xl font-semibold">3rd Place</div>
              <div className="mt-4 text-xl">$250</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PinataChallenge = ({ user }) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("details"); // Track active tab

  const info = {
    title: "Pinata Chess Challenge",
    startDate: "2024-11-17T12:00:00",
    endDate: "2024-11-23T12:00:00",
    game: GamesInfo.chess.image,
  };

  const calculateTimeLeft = () => {
    const now = new Date();
    const startDate = new Date(info.startDate);
    const endDate = new Date(info.endDate);
    const timeToStart = startDate - now;
    const timeToEnd = endDate - now;

    if (timeToStart > 0) {
      return { status: "starting", timeLeft: timeToStart };
    } else if (timeToEnd > 0) {
      return { status: "active", timeLeft: timeToEnd };
    } else {
      return { status: "ended", timeLeft: null };
    }
  };

  const [timeStatus, setTimeStatus] = useState(calculateTimeLeft());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeStatus(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (time) => {
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((time % (1000 * 60)) / 1000);
    return { days, hours, minutes, seconds };
  };

  return (
    <div className="flex flex-col items-center gap-6 px-8 py-8 md:px-10">
      <div className="flex flex-col w-full 2xl:max-w-screen-xl xl:max-w-screen-lg lg:max-w-screen-md gap-2">
        <div
          onClick={() => navigate("/contest")}
          className="select-none mb-4 text-onSurface-light dark:text-onSurface-dark cursor-pointer"
        >
          &#9664; Back to Contest
        </div>
        <div className="flex justify-between items-center">
          <div className="text-4xl font-bold text-onSurface-light dark:text-onSurface-dark">
            Pinata Chess Challenge
          </div>
          <img className="w-20" src={Pinata} />
        </div>

        {timeStatus.status === "starting" && (
          <div className="bg-yellow-100 text-yellow-700 py-4 px-6 mt-6 rounded-md">
            <span>The contest will start in </span>
            <span>{`${formatTime(timeStatus.timeLeft).days}d ${
              formatTime(timeStatus.timeLeft).hours
            }h ${formatTime(timeStatus.timeLeft).minutes}m ${
              formatTime(timeStatus.timeLeft).seconds
            }s`}</span>
          </div>
        )}

        {timeStatus.status === "active" && (
          <div className="bg-green-100 text-green-700 py-4 px-6 mt-6 rounded-md">
            <span>This contest will end in </span>
            <span>{`${formatTime(timeStatus.timeLeft).days}d ${
              formatTime(timeStatus.timeLeft).hours
            }h ${formatTime(timeStatus.timeLeft).minutes}m ${
              formatTime(timeStatus.timeLeft).seconds
            }s`}</span>
          </div>
        )}

        {timeStatus.status === "ended" && (
          <div className="bg-red-100 text-red-700 py-4 px-6 mt-6 rounded-md">
            <span>This contest has ended.</span>
          </div>
        )}

        {/* Tab Navigation */}
        <div className="mt-8 flex justify-center gap-6">
          <button
            className={`py-2 px-4 font-semibold ${
              activeTab === "details"
                ? "border-b-2 border-primary text-primary"
                : "text-gray-500"
            }`}
            onClick={() => setActiveTab("details")}
          >
            Details
          </button>
          <button
            className={`py-2 px-4 font-semibold ${
              activeTab === "compete"
                ? "border-b-2 border-primary text-primary"
                : "text-gray-500"
            }`}
            onClick={() => setActiveTab("compete")}
          >
            Compete
          </button>
          <button
            className={`py-2 px-4 font-semibold ${
              activeTab === "results"
                ? "border-b-2 border-primary text-primary"
                : "text-gray-500"
            }`}
            onClick={() => setActiveTab("results")}
          >
            Results
          </button>
        </div>

        {/* Tab Content */}
        <div className="mt-6">
          {activeTab === "details" && <PinataDetails />}
          {activeTab === "compete" && (
            <div className="p-8 rounded-lg shadow-lg bg-gray-100 dark:bg-gray-900 text-onSurface-light dark:text-onSurface-dark">
              {timeStatus.status === "active" ? (
                <ProtectedRoute>
                  <PinataInstructions user={user} />
                </ProtectedRoute>
              ) : (
                <span className="flex justify-center items-center text-lg font-semibold">
                  This contest is not active.
                </span>
              )}
            </div>
          )}
          {activeTab === "results" && (
            <div className="text-center p-8 rounded-lg shadow-lg bg-gray-100 dark:bg-gray-900 text-onSurface-light dark:text-onSurface-dark">
              {timeStatus.status === "ended" ? (
                <span className="text-lg font-semibold">
                  Results will be posted soon!
                </span>
              ) : (
                <span className="text-lg font-semibold">
                  Check back for results once the contest ends.
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PinataChallenge;
