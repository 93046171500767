import React, { useState } from "react";
import { GamesInfo } from "../../Constants/Games";
import EditConfirm from "../EditConfirmation/EditConfirm";

const CodeTabs = ({ width, game, models, user }) => {
  const userModels = models
    ? Array.from(models.entries()).flatMap(([gameName, entries]) =>
        entries
          .filter((entry) => entry.uid === user.uid && entry.game === game) // Filter by uid and game
          .map((entry) => ({ ...entry, gameName }))
      )
    : [];

  const [activeTab, setActiveTab] = useState("gameSummary");

  return (
    <div
      className="flex flex-col bg-surfaceContain-light dark:bg-surfaceContain-dark"
      style={{ width: width, height: "calc(100vh - 81px)" }}
    >
      <div className="flex items-center justify-center gap-4 p-6">
        <img
          src={GamesInfo[game].image}
          alt={game}
          className="rounded-lg w-8 sm:w-12 sm:h-12"
        />
        <h1 className="text-2xl font-bold text-center text-onSurface-light dark:text-onSurface-dark">
          {GamesInfo[game].title}
        </h1>
      </div>

      {/* Tab Buttons */}
      <div className="flex px-6">
        <button
          className={`w-1/2 py-2 text-center ${
            activeTab === "gameSummary"
              ? "border-b-2 border-primary text-primary"
              : "text-gray-500"
          }`}
          onClick={() => {
            setActiveTab("gameSummary");
          }}
        >
          Summary
        </button>
        <button
          className={`w-1/2 py-2 text-center ${
            activeTab === "submissions"
              ? "border-b-2 border-primary text-primary"
              : "text-gray-500"
          }`}
          onClick={() => {
            setActiveTab("submissions");
          }}
        >
          Submissions
        </button>
      </div>

      {/* Tab Content */}
      <div
        className="flex-grow p-6 overflow-y-auto"
        style={{ maxHeight: "calc(100% - 120px)" }}
      >
        {activeTab === "gameSummary" && (
          <div className="space-y-4">
            <section className="pb-4">
              <h2 className="text-lg font-bold text-onSurface-light dark:text-onSurface-dark">
                Goal of the game:
              </h2>
              <p className="text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark">
                {GamesInfo[game].goal}
              </p>
            </section>
            <section className="pb-4">
              <h2 className="text-lg font-bold text-onSurface-light dark:text-onSurface-dark">
                What to implement:
              </h2>
              <div className="whitespace-pre-wrap text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark">
                {(() => {
                  const ProblemStatement = GamesInfo[game].problemStatement;
                  return <ProblemStatement />;
                })()}
              </div>
            </section>
          </div>
        )}
        {activeTab === "submissions" && (
          <ul className="space-y-4">
            {userModels.length > 0 ? (
              userModels.map((model, i) => (
                <li
                  key={i}
                  className="flex justify-between items-center p-4 bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-400 rounded-lg shadow"
                >
                  <p className="text-lg font-medium">{model.name}</p>
                  <div className="flex gap-8">
                    <p className="text-sm">{model.rating}</p>
                    <EditConfirm game={game} model={model} />
                  </div>
                </li>
              ))
            ) : (
              <div className="text-center text-gray-600 dark:text-gray-400">
                No submissions
              </div>
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export default CodeTabs;
