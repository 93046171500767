import { TbInfoCircleFilled } from "react-icons/tb";

function IconWithTooltip({ tooltipText }) {
  return (
    <div className="lg:flex sm:hidden relative inline-block group">
      {/* Icon */}
      <TbInfoCircleFilled className="text-xl text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark" />

      {/* Tooltip */}
      <div className="absolute w-48 p-2 mt-2 text-sm text-center transition-opacity duration-300 transform rounded-lg opacity-0 text-onSurfaceSecond-light border-[1px] border-border-light dark:border-border-dark dark:text-onSurfaceSecond-dark shadow-md bg-surfaceContain-light dark:bg-surfaceContain-dark left-1/2 top-full group-hover:opacity-100 ">
        {tooltipText}
      </div>
    </div>
  );
}

export default IconWithTooltip;
