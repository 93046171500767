import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
} from "chart.js";

// Register necessary chart components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip
);

const ProgessGraph = ({ modelId, history, dateCreated, initialRating }) => {
  // Extract labels (timestamps) and data (ratings) from the history array
  const labels = history ? history.map(item => new Date(item.timestamp).toLocaleDateString()) : [new Date(dateCreated).toLocaleDateString()];
  const dataPoints = history ? history.map(item => item.player1ModelId === modelId ? item.player1EloAfter : item.player2EloAfter) : [initialRating];

  const data = {
    labels: labels, // Use the extracted timestamps as labels
    datasets: [
      {
        data: dataPoints, // Use the extracted ratings as data
        fill: true,
        backgroundColor: "rgba(334,38,95,0.1)",
        borderColor: "rgba(334,38,95,0.5)",
        tension: 0.4,
        borderWidth: 2,
        pointRadius: labels.length < 5 ? 6 : 1,
        pointHoverRadius: 6,
      },
    ],
  };

  const minYValue = Math.min(...dataPoints) - 5;
  const maxYValue = Math.max(...dataPoints) + 10;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem) {
            return `Rating: ${tooltipItem.formattedValue}`;
          },
        },
        displayColors: false,
      },
    },
    scales: {
      x: {
        display: true, // Display x-axis (timestamps)
        ticks: {
          maxTicksLimit: Math.min(labels.length, 5), // Limit the number of ticks
          autoSkip: true, // Automatically skip some ticks if necessary
          maxRotation: 0, // Prevent rotation for better visibility
        },
      },
      y: {
        display: true,
        beginAtZero: false,
        min: minYValue,
        max: maxYValue,
      },
    },
  };

  return <Line data={data} options={options} />;
};

export default ProgessGraph;
