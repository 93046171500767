import React, { useState, useEffect } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark as dark, base16AteliersulphurpoolLight as light  } from 'react-syntax-highlighter/dist/esm/styles/prism';

const FillerBgMap = {
  1: "bg-red-500",
  2: "bg-orange-500",
  3: "bg-yellow-400",
  4: "bg-green-600",
  5: "bg-green-100",
  6: "bg-purple-500",
  7: "bg-teal-500",
  8: "bg-pink-500",
};

const FillerBorderMap = {
  1: "border-red-500",
  2: "border-orange-500",
  3: "border-yellow-400",
  4: "border-green-600",
  5: "border-green-100",
  6: "border-purple-500",
  7: "border-teal-500",
  8: "border-pink-500",
};

export const FillerGameView = ({ gameData, setShowWinner }) => {
  const moves = gameData.moves ? JSON.parse(gameData.moves) : [];

  const [currentMove, setCurrentMove] = useState(0);
  const [showAnimation, setShowAnimation] = useState(true);

  const board = moves[currentMove];

  useEffect(() => {
    if (currentMove < moves.length && showAnimation) {
      const timeout = setTimeout(() => {
        if (currentMove < moves.length - 1) {
          setCurrentMove(currentMove + 1);
        } else {
          setShowAnimation(false);
          setShowWinner(true);
        }
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [currentMove, showAnimation]);

  return (
    <div className="flex flex-col justify-center">
      <div className="grid flex justify-center items-center">
        {board.map((row, rowIndex) => (
          <div key={rowIndex} className="flex">
            {row.map((cell, colIndex) => (
              <div
                key={colIndex}
                className={`relative w-12 h-12 flex justify-center items-center border border-gray-300 transform transition-all duration-500 ease-in-out ${FillerBgMap[cell]} ${FillerBorderMap[cell]}`}
              ></div>
            ))}
          </div>
        ))}
      </div>
      {!showAnimation && (
        <div className="flex justify-center mt-4">
          <button
            className="px-4 py-2 bg-gray-300 mr-2 rounded disabled:opacity-50"
            onClick={() => {
              if (currentMove === moves.length - 1) {
                setShowWinner(false);
              }
              setCurrentMove(currentMove - 1);
            }}
            disabled={currentMove === 0}
          >
            Previous
          </button>
          <button
            className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
            onClick={() => {
              if (currentMove + 1 === moves.length - 1) {
                setShowWinner(true);
              }
              setCurrentMove(currentMove + 1);
            }}
            disabled={currentMove === moves.length - 1}
          >
            Next
          </button>
        </div>
      )}

      {currentMove < moves.length - 1 ? (
        <div className="flex justify-center mt-4">
          <button
            className="px-4 py-2 bg-gray-300 mr-2 rounded disabled:opacity-50"
            onClick={() => setShowAnimation(!showAnimation)}
          >
            {showAnimation ? "Pause Autoplay" : "Autoplay"}
          </button>
        </div>
      ) : (
        <div className="flex justify-center mt-4">
          <button
            className="px-4 py-2 bg-gray-300 mr-2 rounded disabled:opacity-50"
            onClick={() => {
              setCurrentMove(0);
              if (moves.length !== 1) {
                setShowWinner(false);
              }
            }}
          >
            Replay
          </button>
        </div>
      )}
    </div>
  );
};

export const FillerDescription = `Capture more cells than your opponent by spreading your color across the grid. Choose colors wisely and expand your territory!`;

export const FillerProblemStatement = () => {

  const isDarkMode = document.documentElement.classList.contains("dark");

  const gameStateExample = `gameState = [
        [1, 3, 4, 1, 2, 6, 4, 1],
        [4, 2, 3, 5, 1, 4, 2, 3],
        [1, 6, 4, 3, 2, 1, 5, 4],
        [3, 1, 2, 6, 4, 3, 2, 6],
        [5, 2, 1, 4, 3, 2, 4, 1],
        [2, 5, 4, 1, 6, 5, 3, 4],
        [3, 4, 1, 2, 5, 6, 1, 2],
        [1, 4, 2, 3, 2, 5, 6, 4]
  ]`;

  const gameStateAfterMove = `gameState = [
        [1, 3, 4, 1, 2, 6, 4, 1],
        [4, 2, 3, 5, 1, 4, 2, 3],
        [1, 6, 4, 3, 2, 1, 5, 4],
        [3, 1, 2, 6, 4, 3, 2, 6],
        [5, 2, 1, 4, 3, 2, 4, 1],
        [2, 5, 4, 1, 6, 5, 3, 4],
        [3, 4, 1, 2, 5, 6, 1, 2],
        [1, 4, 2, 3, 2, 5, 5, 5]
  ]`;

  return (
    <div className="space-y-4">
      <p>
        You must implement a class with a method makeMove() that takes in the
        current state of the board and returns a new color.
      </p>
      <p>
        A gameState is represented as an 8 row by 8 column 2D array of integers,
        where each number corresponds to a different color (1-8). Player1 starts
        at the top-right corner (0,7) and Player2 starts at the bottom-left
        corner (7,0).
      </p>
      <p>Here is an example of a starting gameState:</p>
      {isDarkMode ? 
        <div style={{fontSize:'16px'}}>
          <SyntaxHighlighter language="python"  style={isDarkMode ? dark : light} >
            {gameStateExample}
          </SyntaxHighlighter>
        </div> 
      :
        <SyntaxHighlighter language="python">
          {gameStateExample}
        </SyntaxHighlighter>
      }
      <p>
        Your makeMove() function should return a color (1-8) that the player
        wants to switch to. After player1 chooses color 5, the gameState
        becomes:
      </p>
      {isDarkMode ? 
        <div style={{fontSize:'16px'}}>
          <SyntaxHighlighter language="python"  style={isDarkMode ? dark : light} >
            {gameStateAfterMove}
          </SyntaxHighlighter>
        </div> 
      :
        <SyntaxHighlighter language="python">
          {gameStateAfterMove}
        </SyntaxHighlighter>
      }
      <p>And player1 expands their territory!</p>
      <section className="pt-4 pb-4">
        <h2 className="text-lg font-bold dark:text-white text-black">
          3rd party imports:
        </h2>
        <div className="whitespace-pre-wrap">
          {isDarkMode ? 
            <div style={{fontSize:'16px'}}>
              <SyntaxHighlighter language="python"  style={isDarkMode ? dark : light} >
                numpy, pandas, torch
              </SyntaxHighlighter>
            </div> 
          :
            <SyntaxHighlighter language="python">
                numpy, pandas, torch
            </SyntaxHighlighter>
          }
        </div>
      </section>
      <section className="pb-4">
        <h2 className="text-lg font-bold dark:text-white text-black">
          Constraints:
        </h2>
        <div className="whitespace-pre-wrap">
          <ul className="pl-5 list-disc">
            <li>Time to instantiate class: 2 seconds</li>
            <li>Time per move: 2 seconds</li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export const FillerStarterCode = `import random

# Random bot
# Update the makeMove function
class Player:
    def __init__(self, playerOne: bool):
        self.player_number = 1 if playerOne else 2

    def makeMove(self, gameState: list[list[int]]) -> int:
        opp_color = 1
        current_color = 1

        if self.player_number == 1:
            current_color = gameState[7][0]
            opp_color = gameState[0][7]
        else:
            current_color = gameState[0][7]
            opp_color = gameState[7][0]

        colors = [i for i in range(1, 9) if i not in [opp_color, current_color]]

        return random.choice(colors)

`;

export const FillerGameCode = `# the filler class that will be used to judge your code
class Filler:
    def __init__(self):
        self.board = [[0] * 8 for _ in range(8)]
        for i in range(8):
            for j in range(8):
                self.board[i][j] = random.randint(1, 8)
        
        self.p2set = set([(0,7)])
        self.p1set = set([(7,0)])
        self.p2c = self.board[0][7]
        self.p1c = self.board[7][0]
        self.turn = 1
        self.dirs = [(0,1), (1,0), (0,-1), (-1,0)]
        self.moves = [deepcopy(self.board)]
    
    def get_valid_moves(self):
        current_color = self.p1c if self.turn == 1 else self.p2c
        opponent_color = self.p2c if self.turn == 1 else self.p1c
        
        valid_colors = set(range(1, 9)) - {current_color, opponent_color}
        return list(valid_colors)
    
    def make_move(self, new_color):
        if new_color not in self.get_valid_moves():
            return False
        
        player_set = self.p1set if self.turn == 1 else self.p2set
        vis = set()
        
        def dfs(i, j):
            if -1 in {i, j} or 8 in {i, j} or (i,j) in vis:
                return 
            if self.board[i][j] != new_color and (i,j) not in player_set:
                return
            if self.board[i][j] == new_color and (i,j) not in player_set:
                player_set.add((i,j))
            elif (i,j) in player_set:
                self.board[i][j] = new_color
            vis.add((i,j))
            for dx, dy in self.dirs:
                dfs(i + dx, j + dy)
        
        for i, j in player_set.copy():
            dfs(i,j)
        
        if self.turn == 1:
            self.p1c = new_color
        else:
            self.p2c = new_color
        
        self.turn = 3 - self.turn
        
        return True
    
    def detect_win(self):
        colors = defaultdict(int)
        for i in range(len(self.board)):
            for j in range(len(self.board[0])):
                colors[self.board[i][j]] += 1
        
        if len(colors) > 2:
            return 0
        
        if colors[self.p1c] > colors[self.p2c]:
            return 1
        elif colors[self.p1c] < colors[self.p2c]:
            return 2
 
        return 3
`;
