import React, { useState, useEffect } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark as dark, base16AteliersulphurpoolLight as light  } from 'react-syntax-highlighter/dist/esm/styles/prism';


export const Connect4GameView = ({ gameData, setShowWinner }) => {
  const moves = JSON.parse(gameData.moves);
  const [currentMove, setCurrentMove] = useState(0);
  const [showAnimation, setShowAnimation] = useState(true);
  const board = moves[currentMove];

  // Update the board to the next state when `currentMove` changes
  useEffect(() => {
    if (currentMove < moves.length && showAnimation) {
      // Automatically move to the next grid after a delay
      const timeout = setTimeout(() => {
        if (currentMove < moves.length - 1) {
          setCurrentMove(currentMove + 1);
        } else {
          setShowAnimation(false);
          setShowWinner(true);
        }
      }, 1000); // Adjust this delay for speed of animation

      return () => clearTimeout(timeout); // Clean up the timeout on unmount
    }
  }, [currentMove, showAnimation]);

  return (
    <div className="flex flex-col justify-center">
      <div className="grid bg-blue-600 rounded-lg p-2">
        {board.map((row, rowIndex) => (
          <div key={rowIndex} className="flex">
            {row.map((cell, colIndex) => (
              <div
                key={colIndex}
                className="relative flex items-center justify-center w-12 h-12 rounded-lg"
              >
                <div
                  className={`w-10 h-10 rounded-full transform transition-all duration-500 ease-in-out ${
                    cell === 1
                      ? "bg-red-500" // Player 1 (Red)
                      : cell === 2
                      ? "bg-yellow-400" // Player 2 (Yellow)
                      : "bg-white" // Empty space
                  }`}
                ></div>
              </div>
            ))}
          </div>
        ))}
      </div>

      {!showAnimation && (
        <div className="flex justify-center mt-4">
          <button
            className="px-4 py-2 mr-2 bg-gray-300 rounded disabled:opacity-50"
            onClick={() => {
              if (currentMove === moves.length - 1) {
                setShowWinner(false);
              }
              setCurrentMove(currentMove - 1);
            }}
            disabled={currentMove === 0}
          >
            Previous
          </button>
          <button
            className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
            onClick={() => {
              if (currentMove + 1 === moves.length - 1) {
                setShowWinner(true);
              }
              setCurrentMove(currentMove + 1);
            }}
            disabled={currentMove === moves.length - 1}
          >
            Next
          </button>
        </div>
      )}
      {currentMove < moves.length - 1 ? (
        <div className="flex justify-center mt-4">
          <button
            className="px-4 py-2 mr-2 bg-gray-300 rounded disabled:opacity-50"
            onClick={() => setShowAnimation(!showAnimation)}
          >
            {showAnimation ? "Pause Autoplay" : "Autoplay"}
          </button>
        </div>
      ) : (
        <div className="flex justify-center mt-4">
          <button
            className="px-4 py-2 mr-2 bg-gray-300 rounded disabled:opacity-50"
            onClick={() => {
              setCurrentMove(0);
              if (moves.length !== 1) {
                setShowWinner(false);
              }
            }}
          >
            Replay
          </button>
        </div>
      )}
    </div>
  );
};

export const Connect4Description = `Connect 4 discs in the grid! Connections can be horizontal, vertical, or diagonal.`;

export const Connect4ProblemStatement = () => {
  const gameStateExample = `gameState = [
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 2, 0, 0, 0, 0],
        [0, 0, 1, 0, 0, 0, 0],
        [0, 0, 1, 0, 1, 1, 0],
        [0, 0, 2, 1, 2, 2, 2]
  ]`;

  const gameStateAfterMove = `gameState = [
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 2, 0, 0, 0, 0],
        [0, 0, 1, 0, 0, 0, 0],
        [0, 0, 1, 1, 1, 1, 0],
        [0, 0, 2, 1, 2, 2, 2]
  ]`;

  const isDarkMode = document.documentElement.classList.contains("dark");

  return (
    <div className="space-y-4">
      <p>
        You must implement a class with a method makeMove() that takes in a
        board and returns a move.
      </p>
      <p>
        A gameState is represented as a 6 row by 7 column 2d array of integers,
        where 1&apos;s and 2&apos;s represent player1&apos;s disks and
        player2&apos;s disks and 0&apos;s represent an empty slot.
      </p>
      <p>Here is an example:</p>

      {isDarkMode ? 
        <div style={{fontSize:'16px'}}>
          <SyntaxHighlighter language="python"  style={isDarkMode ? dark : light} >
            {gameStateExample}
          </SyntaxHighlighter>
        </div> 
      :
        <SyntaxHighlighter language="python">
          {gameStateExample}
        </SyntaxHighlighter>
      }
      <p>
        Your makeMove() function returns a column [0,6] to drop your disk in. So
        if player1 chooses to drop a disk in column 3, then the gameState
        becomes:
      </p>
      {isDarkMode ? 
        <div style={{fontSize:'16px'}}>
          <SyntaxHighlighter language="python"  style={isDarkMode ? dark : light} >
            {gameStateAfterMove}
          </SyntaxHighlighter>
        </div> 
      :
        <SyntaxHighlighter language="python">
          {gameStateAfterMove}
        </SyntaxHighlighter>
      }
      <p>And player1 wins the game!</p>
      <section className="pt-4 pb-4">
        <h2 className="text-lg font-bold dark:text-white text-black">
          3rd party imports:
        </h2>
        <div className="whitespace-pre-wrap">
          {isDarkMode ? 
            <div style={{fontSize:'16px'}}>
              <SyntaxHighlighter language="python"  style={isDarkMode ? dark : light} >
                numpy, pandas, torch
              </SyntaxHighlighter>
            </div> 
          :
            <SyntaxHighlighter language="python">
                numpy, pandas, torch
            </SyntaxHighlighter>
          }
        </div>
      </section>
      <section className="pb-4">
        <h2 className="text-lg font-bold dark:text-white text-black">
          Constraints:
        </h2>
        <div className="whitespace-pre-wrap">
          <ul className="pl-5 list-disc">
            <li>Time to instantiate class: 2 seconds</li>
            <li>Time per move: 2 seconds</li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export const Connect4VisualMap = {
  1: (
    <div
      className={`w-6 sm:w-8 h-6 sm:h-8 rounded-full transform transition-all duration-500 ease-in-out bg-red-500`}
    ></div>
  ),
  2: (
    <div
      className={`w-6 sm:w-8 h-6 sm:h-8 rounded-full transform transition-all duration-500 ease-in-out bg-yellow-400`}
    ></div>
  ),
};

export const Connect4StarterCode = `import numpy as np

# Fill in the makeMove function
class Player:

    def __init__(self, playerOne: bool):
        pass

    def makeMove(self, gameState: list[list[int]]) -> int:
        return np.random.randint(0, 7)
`;
export const Connect4GameCode = `# the connect4 class that will be used to judge your code
class Connect4:
    def __init__(self):
        self.board = [[0] * 7 for _ in range(6)]
        self.turn = 1 # player 1 goes first

    def detect_win(self):
        dirs = [(0,1), (1,0), (1,1), (1,-1)]
        rows, cols = 6, 7
        
        for x in range(rows):
            for y in range(cols):
                if self.board[x][y] != 0:
                    for dx, dy in dirs:
                        if all(0 <= x+i*dx < rows and 0 <= y+i*dy < cols and 
                               self.board[x+i*dx][y+i*dy] == self.board[x][y] for i in range(4)):
                            return self.board[x][y]
        return 0

    def make_move(self, col):
        if col < 0 or col > 6:
            return False
        for row in range(6):
            if self.board[row][col] == 0:
                self.board[row][col] = self.turn
                self.turn = 3 - self.turn
                return True
        return False
`;
