import React from "react";

const PrimaryButton = ({ children, onClick, disabled }) => {
  return (
    <button
      onClick={onClick}
      className={`font-semibold box-border px-6 py-2 text-md font-medium dark:bg-primary-dark rounded-md text-onSurface border-b-2 border-r-2 bg-primary-light border-border dark:border-[#B2519F] hover:translate-y-0.5 hover:border-b-2 hover:border-transparent dark:hover:border-b-2 dark:hover:border-transparent transition-all duration-300 ${
        disabled ? "cursor-not-allowed" : ""
      }`}
      style={{ minWidth: "fit-content" }}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default PrimaryButton;
