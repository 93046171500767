import React from "react";
import { TbZoom } from "react-icons/tb";

function SearchBar({ setSearchTerm, placeholder = "Search" }) {
  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className="relative min-w-full">
      {/* Icon */}
      <TbZoom className="absolute transform -translate-y-1/2 text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark size-4 left-4 top-1/2" />

      {/* Input Field */}
      <input
        className="w-full px-10 py-3 border-[1px] border-border-light dark:border-border-dark rounded-lg bg-surfaceContain-light dark:bg-surfaceContain-dark focus:dark:border-primary hover:dark:border-primary transition-all duration-300 ease-out focus:border-primary outline-none text-onSurface-light dark:text-onSurface-dark text-sm hover:border-primary"
        type="text"
        placeholder={placeholder}
        onChange={handleInputChange}
      />
    </div>
  );
}

export default SearchBar;
