import * as React from "react";
import { useNavigate } from "react-router-dom";
import { GamesInfo } from "../../Constants/Games";
import IconWithTooltip from "../Tooltip/Tooltip";

function GameCard({ game }) {
  let navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(`/editor/${game}`)}
      className="p-6 rounded-lg border-[1px] w-full cursor-pointer border-border-light dark:border-border-dark hover:shadow-md dark:hover:shadow-neutral-800 dark:hover-shadow-sm bg-surfaceContain-light dark:bg-surfaceContain-dark hover:scale-[1.03] hover:dark:border-neutral-700 hover:border-neutral-300 duration-200 transition-all ease-linear"
    >
      {/* Card Container */}
      <div className="flex flex-col w-full gap-6">
        <img
          src={GamesInfo[game].image}
          alt={game}
          className="rounded-lg md:w-full md:h-auto"
        />
        {/* Card Content Container */}

        <div className="flex items-end justify-between">
          <div className="text-xl font-normal leading-none dark:text-onSurface-dark">
            {GamesInfo[game].title}
          </div>
          <IconWithTooltip tooltipText={GamesInfo[game].goal} />
        </div>
      </div>
    </div>
  );
}

export default GameCard;
