import { GamesInfo } from "../Constants/Games";

// leaderboard on load
export const getModelsData = async () => {

    const map = new Map()

    for (const key in GamesInfo) {
        const jsonData = { game: key };
        try {
            const res = await fetch(process.env.REACT_APP_endpointURL + "/game/models", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(jsonData)
            });
            if (!res.ok) {
                throw new Error(`HTTP error! status: ${res.status}`);
            }
            const result = await res.json();
            map.set(key, result.map((model, index) => ({
                ...model,
                rank: index + 1, // Assign rank based on the order in the response
            })))
        } catch (error) {
            console.error("Error:", error);
        }
    }

    return map
}

