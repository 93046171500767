import React from "react";
import { useNavigate } from "react-router-dom";
import { FaLinkedin, FaGithub } from "react-icons/fa";
import Jack from "../../Images/Brains/jack.png";
import Chaitra from "../../Images/Brains/chaitra.png";
import BrianRot from "../../Images/Logos/brain.png";
import BrainRotText from "../../Images/Logos/brainrot_text.png";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";

const About = () => {
  let navigate = useNavigate();

  const founders = [
    {
      name: "Jack Rankin",
      role: "Founder",
      icon: Jack,
      linkedin: "https://www.linkedin.com/in/jack-t-rankin/",
      github: "https://github.com/jackrankin",
    },
    {
      name: "Chaitra Pirisingula",
      role: "Founder",
      icon: Chaitra,
      linkedin: "https://www.linkedin.com/in/chaitra-pirisingula/",
      github: "https://github.com/chaitrapirisingula",
    },
  ];

  return (
    <div className="min-h-screen py-8">
      {/* Hero Section */}
      <section className="container px-6 mx-auto text-center md:px-12 flex flex-col items-center justify-center">
        <img
          src={BrianRot}
          alt="brian rot"
          className="w-24 h-24 sm:w-36 sm:h-36 md:w-48 md:h-48 lg:w-48 lg:h-48 p-2"
        />
        <img
          src={BrainRotText}
          alt="brain rot text"
          className="w-auto h-20 sm:h-24 md:h-28 lg:h-28 p-2"
        />
        <p className="max-w-2xl mx-auto mb-8 mt-4 text-lg text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark">
          brAIn rot is designed for developers to create, compare, and visualize
          algorithms & machine learning models on strategy games.
        </p>
        <div className="flex items-center justify-center gap-2">
          <PrimaryButton
            children={"Get Started"}
            onClick={() => {
              navigate("/games");
            }}
          />
        </div>
      </section>

      {/* Features Section */}
      <section className="container px-6 py-16 mx-auto md:px-12">
        <h2 className="mb-12 text-3xl font-bold text-center text-onSurface-light dark:text-onSurface-dark">
          What can you do?{" "}
          <span role="img" aria-label="brain">
            🧠
          </span>
        </h2>
        <div className="flex flex-wrap justify-center gap-12">
          <div
            onClick={() => {
              navigate("/games");
            }}
            className="max-w-sm p-6 rounded-lg bg-surfaceContain-light dark:bg-surfaceContain-dark box-border dark:bg-primary-dark rounded-md border-b-2 border-r-2 bg-primary-light border-border dark:border-[#B2519F] hover:translate-y-0.5 hover:border-b-2 hover:border-transparent dark:hover:border-b-2 dark:hover:border-transparent transition-all duration-300 cursor"
          >
            <h3 className="mb-4 text-xl font-semibold text-onSurface-light dark:text-onSurface-dark">
              <span role="img" aria-label="code">
                👩🏻‍💻
              </span>{" "}
              Create
            </h3>
            <p className="text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark">
              Navigate to our games page to build powerful models to battle on
              strategy games. You can code anything from traditional algorithms
              to advanced machine learning models.
            </p>
          </div>
          <div
            onClick={() => {
              navigate("/battle");
            }}
            className="max-w-sm p-6 rounded-lg bg-surfaceContain-light dark:bg-surfaceContain-dark box-border dark:bg-primary-dark rounded-md border-b-2 border-r-2 bg-primary-light border-border dark:border-[#B2519F] hover:translate-y-0.5 hover:border-b-2 hover:border-transparent dark:hover:border-b-2 dark:hover:border-transparent transition-all duration-300 cursor"
          >
            <h3 className="mb-4 text-xl font-semibold text-onSurface-light dark:text-onSurface-dark">
              <span role="img" aria-label="see">
                👀
              </span>{" "}
              Visualize
            </h3>
            <p className="text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark">
              Navigate to our battle page to visualize your model’s
              decision-making process. With our leaderboard and game animations,
              you can gain insight into how your models think and act.
            </p>
          </div>
          <div
            onClick={() => {
              navigate("/contest");
            }}
            className="max-w-sm p-6 rounded-lg bg-surfaceContain-light dark:bg-surfaceContain-dark box-border dark:bg-primary-dark rounded-md border-b-2 border-r-2 bg-primary-light border-border dark:border-[#B2519F] hover:translate-y-0.5 hover:border-b-2 hover:border-transparent dark:hover:border-b-2 dark:hover:border-transparent transition-all duration-300 cursor"
          >
            <h3 className="mb-4 text-xl font-semibold text-onSurface-light dark:text-onSurface-dark">
              <span role="img" aria-label="battle">
                🤺
              </span>{" "}
              Compete!
            </h3>
            <p className="text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark">
              Navigate to our contest page to learn more about sponsoring or
              participating in AI challenges. Test your models against others
              and win cool prizes!
            </p>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section id="contact" className="py-16">
        <div className="container px-6 mx-auto md:px-12">
          <h2 className="mb-8 text-3xl font-bold text-center text-onSurface-light dark:text-onSurface-dark">
            Contact Us
          </h2>
          <p className="mb-12 text-lg text-center text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark">
            Have questions or want to collaborate? We’d love to hear from you!
            💖
          </p>
          <div className="flex flex-col items-center mb-12">
            <div className="w-full max-w-lg">
              <div className="p-6 rounded-lg shadow-lg bg-surfaceContain-light dark:bg-surfaceContain-dark box-border border-b-2 border-r-2 border-border dark:border-[#B2519F]">
                <h3 className="mb-4 text-2xl font-semibold text-onSurface-light dark:text-onSurface-dark">
                  Reach Out
                </h3>
                <p className="text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark">
                  Email:
                </p>
                <a
                  href="mailto:brainrot4ai@gmail.com"
                  className="text-blue-600 dark:text-blue-400 hover:underline"
                >
                  brainrot4ai@gmail.com
                </a>
                <p className="mt-6 text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark">
                  Connect:
                </p>
                <a
                  className="flex items-center gap-2 text-blue-600 dark:text-blue-400 hover:underline"
                  href="https://www.linkedin.com/company/brain-rot"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin className="text-xl text-blue-600 dark:text-blue-400" />
                  LinkedIn
                </a>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap justify-center gap-12 mb-12">
            {founders.map((member) => (
              <div
                className="flex items-center max-w-xs p-4 rounded-lg shadow-lg bg-surfaceContain-light-light dark:bg-surfaceContain-dark box-border border-b-2 border-r-2 border-border dark:border-[#B2519F]"
                key={member.name}
              >
                <img
                  src={member.icon}
                  alt="Winner"
                  className="w-auto h-32 p-4"
                />
                <div>
                  <h3 className="text-xl font-semibold text-onSurface-light dark:text-onSurface-dark">
                    {member.name}
                  </h3>
                  <p className="text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark">
                    {member.role}
                  </p>
                  <div className="flex gap-4 mt-2">
                    <a
                      href={member.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaLinkedin className="text-xl text-blue-600 dark:text-blue-400" />
                    </a>
                    <a
                      href={member.github}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaGithub className="text-xl text-onSurface-light dark:text-onSurface-dark" />
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
