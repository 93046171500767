import React from "react";
import ConfusedBrain from "../../Images/Brains/confused.png";

function NotFound() {
  return (
    <div className="flex flex-col gap-4 items-center justify-center mt-20">
      <img src={ConfusedBrain} alt="Loading" className="h-40" />
      <p className="text-lg font-semibold text-onSurface-light dark:text-onSurface-dark">
        Can't find what you are looking for :(
      </p>
    </div>
  );
}

export default NotFound;
