import React from "react";
import PrimaryButton from "../Buttons/PrimaryButton";

const RecentBattleCard = ({ modelId, data, setShowBattle }) => {
  const isPlayer1 = data.player1ModelId === modelId;
  const eloChange = isPlayer1
    ? data.player1EloAfter - data.player1EloBefore
    : data.player2EloAfter - data.player2EloBefore;
  const isWinner =
    (data.winner === 1 && data.player1ModelId === modelId) ||
    (data.winner === 2 && data.player2ModelId === modelId);
  const resultColor = isWinner ? "text-green-500" : "text-red-500";

  return (
    <div className="flex gap-4 w-full p-6 items-center justify-between bg-surfaceContain-light dark:bg-surfaceContain-dark border-b-[1px] border-border-light dark:border-border-dark">
      {/* Result with conditional color */}
      <div className="flex flex-row items-center gap-4">
        <p className={`text-lg font-medium uppercase ${resultColor}`}>
          {isWinner ? "WIN" : "LOSS"}
        </p>
        <div className="flex gap-2">
          <p className="font-medium text-onSurface-light dark:text-onSurface-dark">
            {isPlayer1 ? data.player2ModelName : data.player1ModelName}
          </p>
          <p className={eloChange > 0 ? "text-green-600" : "text-red-600"}>
            {eloChange > 0 && "+"}
            {eloChange}
          </p>
        </div>
      </div>

      <PrimaryButton
        onClick={() => {
          setShowBattle(data);
        }}
        children={"View Battle"}
      />
    </div>
  );
};

export default RecentBattleCard;
