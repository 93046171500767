import React, { useEffect, useState } from "react";
import { auth, signOut } from "../../Firebase/firebaseAuth";
import { useNavigate } from "react-router-dom";
import ProfileInfo from "../../Components/ProfileInfo/ProfileInfo";
import ModelFilters from "../../Components/ModelFilter/ModelFilter";
import SearchBar from "../../Components/SearchBar/SearchBar";
import { GamesInfo } from "../../Constants/Games";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";

function Profile({ user, name, icon, allModels }) {
  const models = allModels
    ? Array.from(allModels.entries())
        .flatMap(([game, entries]) =>
          entries
            .filter((entry) => entry.uid === user.uid)
            .map((entry) => ({ ...entry, game }))
        )
        .sort((a, b) => b.rating - a.rating)
    : [];

  const navigate = useNavigate();

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const logout = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (err) {
      console.error(err);
    }
  };

  const handleFilterSelect = (newFilters) => {
    setSelectedFilters(newFilters);
  };

  const modelsToDisplay = models
    .filter((model) =>
      selectedFilters.length > 0 ? selectedFilters.includes(model.game) : true
    )
    .filter((model) =>
      model.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

  return (
    <div className="flex flex-col items-center gap-8 px-4 py-6 md:px-8 lg:px-10 lg:py-8">
      <div className="flex flex-col w-full gap-12 xl:max-w-screen-lg lg:max-w-screen-md">
        {/* Profile Info */}
        <ProfileInfo user={user} logout={logout} name={name} icon={icon} />

        {/* Profile Models */}
        <div className="flex flex-col gap-4">
          <p className="text-xl font-medium sm:text-2xl text-onSurface-light dark:text-onSurface-dark">
            Models
          </p>

          {/* Model Filters */}
          <div className="flex flex-col gap-4">
            <ModelFilters
              selectedFilters={selectedFilters}
              onFilterSelect={handleFilterSelect}
            />
            <SearchBar
              setSearchTerm={setSearchTerm}
              placeholder="Search Models"
            />
          </div>

          {/* Models Container */}
          <div className="flex flex-col border rounded-lg bg-surfaceContain-light dark:bg-surfaceContain-dark border-border-light dark:border-border-dark">
            {modelsToDisplay.length > 0 ? (
              modelsToDisplay.map((model, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center justify-between px-6 py-4 border-b sm:flex-row border-border-light dark:border-border-dark sm:py-6"
                >
                  {/* Rating and Model Name */}
                  <div className="flex items-center gap-4 sm:gap-6">
                    <p className="text-lg font-bold sm:text-xl text-onSurface-light dark:text-onSurface-dark">
                      {model.rating}
                    </p>
                    <p className="font-normal text-md sm:text-lg text-onSurface-light dark:text-onSurface-dark">
                      {model.name}
                    </p>
                  </div>
                  {/* Game and View */}
                  <div className="flex items-center gap-4 sm:gap-6">
                    <p className="text-sm sm:text-md text-onSurface-light dark:text-onSurface-dark">
                      {GamesInfo[model.game].title}
                    </p>
                    <PrimaryButton
                      onClick={() =>
                        navigate(`/submission/${model.game}/${model.modelId}`)
                      }
                      children={"View Details"}
                    />
                  </div>
                </div>
              ))
            ) : (
              <p className="py-4 text-center text-onSurface-light dark:text-onSurface-dark">
                No models found
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
