import React from "react";
import PinataWhite from "../Images/Contests/pinatachesswhite.png";
import PinataBlack from "../Images/Contests/pinatachessblack.png";
import PinataLogo from "../Images/Logos/pinata.webp";

export const contestIcons = {
  pinata2024chess: PinataLogo,
};

export const chessContestMap = {
  pinata2024chess: {
    wN: ({ squareWidth, isDragging }) => (
      <img
        style={{
          width: isDragging ? squareWidth * 1.75 : squareWidth,
          height: isDragging ? squareWidth * 1.75 : squareWidth,
        }}
        src={PinataWhite}
        alt={"white knight"}
      />
    ),
    bN: ({ squareWidth, isDragging }) => (
      <img
        style={{
          width: isDragging ? squareWidth * 1.75 : squareWidth,
          height: isDragging ? squareWidth * 1.75 : squareWidth,
        }}
        src={PinataBlack}
        alt={"black knight"}
      />
    ),
  },
};
