import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./Firebase/firebaseAuth";
import GameMenu from "./Pages/GameMenu/GameMenu";
import Editor from "./Pages/Editor/Editor";
import ProtectedRoute from "./protectedRoute";
import Header from "./Components/Header/Header";
import Profile from "./Pages/Profile/Profile";
import SubmissionPage from "./Pages/Submission/Submission";
import Loading from "./Components/Loading/Loading";
import NotFound from "./Pages/NotFound/NotFound";
import { getModelsData } from "./Helpers/Endpoints";
import Battle from "./Pages/Battle/Battle";
import About from "./Pages/About/About";
import ErrorPage from "./Pages/Error/Error";
import HackMidwest from "./Pages/Contests/HackMidwest";
import PinataChallenge from "./Pages/Contests/PinataChallenge";
import Contests from "./Pages/Contests/Contests";
import { ChessPlayView } from "./Constants/Chess";
import ContestBanner from "./Components/ContestBanner/ContestBanner";

const useDarkMode = () => {
  const [darkMode, setDarkMode] = useState(() => {
    const storedPreference = localStorage.getItem("darkMode");
    if (storedPreference) {
      return JSON.parse(storedPreference);
    } else {
      return window.matchMedia("(prefers-color-scheme: dark)").matches;
    }
  });

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
      document.body.style.backgroundColor = "#1f2125";
    } else {
      document.documentElement.classList.remove("dark");
      document.body.style.backgroundColor = "#FCFCFC";
    }
    localStorage.setItem("darkMode", JSON.stringify(darkMode));
  }, [darkMode]);

  return [darkMode, setDarkMode];
};

const App = () => {
  const [user, auth_loading, error] = useAuthState(auth);
  // Ensures darkmode is on by default
  useDarkMode();

  const [name, setName] = useState("");
  const [icon, setIcon] = useState(0);
  const [loading, setLoading] = useState(true);
  const [models, setModels] = useState("");
  const [modelsLoading, setModelsLoading] = useState(true);
  const [reload, setReload] = useState(true);

  const getUserInfo = async () => {
    try {
      const token = await user.getIdToken();
      const jsonData = { uid: user.uid };
      const res = await fetch(
        process.env.REACT_APP_endpointURL + "/user/name",
        {
          method: "POST",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(jsonData),
        }
      );
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      const result = await res.json();
      setName(result.name);
      setIcon(result.icon);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (auth_loading) return;
    if (user) {
      getUserInfo();
    } else {
      setLoading(false);
    }
  }, [user, auth_loading]);

  useEffect(() => {
    // Fetch models data
    getModelsData().then((res) => {
      setModels(res);
      setModelsLoading(false);
    });
  }, [reload]);

  if (loading || auth_loading || modelsLoading) {
    return (
      <div className="h-screen p-8 mt-64">
        <Loading />
      </div>
    );
  }

  if (error) {
    return <ErrorPage />;
  }

  return (
    <Router>
      <Header user={user} icon={icon} />
      <div className="pt-16">
        {/* <ContestBanner /> */}
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<About />} />
          <Route path="/games" element={<GameMenu />} />
          <Route path="/contest" element={<Contests />} />
          <Route path="/contest/hackmidwest" element={<HackMidwest />} />
          <Route
            path="/contest/pinata"
            element={<PinataChallenge user={user} />}
          />
          <Route
            path="/battle"
            element={<Battle models={models} setModelsLoading={setReload} />}
          />
          <Route
            path="/editor/:gameName/:modelId?"
            element={
              <ProtectedRoute>
                <Editor
                  user={user}
                  name={name}
                  icon={icon}
                  setReload={setReload}
                  models={models}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="/submission/:gameName/:modelId"
            element={<SubmissionPage user={user} />}
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile
                  name={name}
                  icon={icon}
                  user={user}
                  allModels={models}
                />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
