import React from "react";
import { GamesInfo } from "../../Constants/Games";

function ModelFilters({
  selectedFilters = [],
  onFilterSelect,
  singleSelect = false,
}) {
  const handleFilterClick = (game) => {
    if (singleSelect) {
      onFilterSelect(game);
    } else {
      if (selectedFilters.includes(game)) {
        onFilterSelect(selectedFilters.filter((filter) => filter !== game));
      } else {
        onFilterSelect([...selectedFilters, game]);
      }
    }
  };

  return (
    <div className="flex flex-wrap gap-2 bg-surface-light dark:bg-surface-dark">
      {Object.keys(GamesInfo).map((game) => (
        <button
          key={game}
          onClick={() => handleFilterClick(game)}
          className={`box-border px-6 py-2 text-md font-semibold font-medium dark:bg-primary-dark rounded-md text-onSurface border-b-2 border-r-2 bg-primary-light border-border dark:border-[#B2519F] hover:translate-y-0.5 hover:border-b-2 hover:border-transparent dark:hover:border-b-2 dark:hover:border-transparent transition-all duration-300 ${
            selectedFilters.includes(game)
              ? "bg-primary text-onSurface"
              : "bg-surfaceContain-light dark:bg-surfaceContain-dark  text-onSurface-light dark:text-onSurface-dark"
          }`}
        >
          {GamesInfo[game].title}
        </button>
      ))}
    </div>
  );
}

export default ModelFilters;
