import React, { useState } from "react";
import { AiFillFlag } from "react-icons/ai"; // Flag icon from react-icons

const ErrorFlag = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="relative inline-block">
      {/* Flag icon */}
      <AiFillFlag
        className="text-red-500 text-xl cursor-pointer"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      />

      {/* Tooltip */}
      {isHovered && (
        <div className="absolute top-1/2 right-full mr-2 transform -translate-y-1/2 w-32 bg-gray-800 text-white text-xs rounded p-1 shadow-lg z-50">
          This bot causes errors
          <div className="absolute top-1/2 right-[-4px] transform -translate-y-1/2 w-2 h-2 bg-gray-800 rotate-45"></div>
        </div>
      )}
    </div>
  );
};

export default ErrorFlag;
