import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import PrimaryButton from "../Buttons/PrimaryButton";

const EditConfirm = ({ game, model }) => {
  const darkMode = document.documentElement.classList.contains("dark");

  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);

  const handleNavigation = () => {
    navigate(`/editor/${game}/${model.modelId}`);
  };

  const modalStyles = darkMode
    ? "bg-gray-800 text-white"
    : "bg-white text-gray-800";

  return (
    <div>
      <FaEdit
        className="text-primary cursor-pointer"
        onClick={() => setShowModal(true)} // Open modal on icon click
      />

      {showModal && (
        <div
          className={`fixed inset-0 z-50 flex items-center justify-center ${
            darkMode ? "bg-black bg-opacity-70" : "bg-gray-100 bg-opacity-90"
          }`}
        >
          <div
            className={`relative w-full max-w-sm p-6 rounded-lg shadow-lg ${modalStyles}`}
          >
            <svg
              onClick={() => setShowModal(false)}
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 30 30"
              className="absolute cursor-pointer top-4 right-4"
              style={{
                fill: darkMode ? "white" : "gray",
              }}
            >
              <path d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z" />
            </svg>
            <div className="text-center">
              <h2 className="text-lg font-semibold mb-4">Edit Model</h2>
              <p className="mb-6">
                Are you sure you want to switch to {model.name}? Unsaved changes
                will be lost.
              </p>
              <div className="flex justify-between gap-2 mt-6">
                <button
                  className="box-border px-5 py-2 text-sm font-medium dark:bg-gray-600 bg-gray-400 rounded-md text-onSurface-light dark:text-onSurface-dark border-b-2 border-r-2 bg-surface border-border dark:border-border-dark hover:translate-y-0.5 hover:border-b-2 hover:border-transparent dark:hover:border-b-2 dark:hover:border-transparent transition-all duration-300"
                  onClick={() => setShowModal(false)} // Cancel and close modal
                >
                  Cancel
                </button>
                <PrimaryButton
                  onClick={() => {
                    setShowModal(false);
                    handleNavigation(); // Confirm and navigate
                  }}
                  children={"Edit"}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditConfirm;
