import React from "react";
import HackMidwestLogo from "../../Images/Contests/hackmidwest.png";
import Prizes from "../../Components/Prizes/Prizes";
import TournamentResults from "../../Components/Rounds/Results";
import rounds from "../../Constants/hackmidwestrounds.json";
import finalResults from "../../Constants/hackmidwestfinal.json";
import { useNavigate } from "react-router-dom";

const HackMidwest = () => {
  const navigate = useNavigate();

  const finals = finalResults
    .sort((a, b) => b.wins - a.wins)
    .map((entry, index) => ({
      ...entry,
      rank: index + 1,
    }));

  return (
    <div className="flex flex-col items-center gap-6 px-8 py-8 md:px-10">
      <div className="flex flex-col w-full gap-12 2xl:max-w-screen-xl xl:max-w-screen-lg lg:max-w-screen-md">
        <div>
          <div
            onClick={() => navigate("/contest")}
            className="select-none mb-4 text-onSurface-light dark:text-onSurface-dark cursor-pointer"
          >
            &#9664; Back to Contest
          </div>
          {/* Cover Image */}
          <div className="px-16 py-12 border-[1px] dark:border-border-dark rounded-lg bg-surfaceContain-light dark:bg-surfaceContain-dark border-border-light">
            <img
              src={HackMidwestLogo}
              alt="Hack Midwest Contest"
              className="w-full h-auto"
            />
          </div>
        </div>

        {/* Contest Information */}
        <p className="text-onSurface-light dark:text-onSurface-dark">
          Welcome to brAIn rot's first ever contest!
        </p>

        <TournamentResults
          game={"connect4"}
          rounds={rounds}
          finalResults={finals}
        />

        {/* Prizes Component */}
        <Prizes />
      </div>
    </div>
  );
};

export default HackMidwest;
