import React from "react";
import LogitechKeyboard from "../../Images/Hackmidwest/logitech_keyboard.jpg";
import EchoDot from "../../Images/Hackmidwest/echo_dot.jpg";
import Airpods from "../../Images/Hackmidwest/airpods.png";
import Kirby from "../../Images/Hackmidwest/kirby.png";

const Prizes = () => {
  return (
    <div className="p-4">
      <div className="flex flex-col gap-6">
        <p className="text-2xl font-medium text-onSurface-light dark:text-onSurface-dark">
          Prizes
        </p>
        <div className="flex flex-col md:flex-row gap-4 md:justify-between w-full">
          {/* 1st Place */}
          <div className="bg-surfaceContain-light dark:bg-surfaceContain-dark border-border-light dark:border-border-dark border-[1px] flex flex-col items-center gap-4 rounded-lg p-6">
            <div className="w-full p-4 sm:px-16 sm:py-12 rounded-lg bg-surface-light">
              <img
                src={Airpods}
                className="w-full h-auto max-h-24"
                alt="Airpods"
              />
            </div>
            <p className="text-lg font-medium text-onSurface dark:text-onSurface-dark">
              1st Place
            </p>
            <p className="text-onSurface dark:text-onSurface-dark">Airpods</p>
          </div>

          {/* 2nd Place */}
          <div className="bg-surfaceContain-light dark:bg-surfaceContain-dark border-border-light dark:border-border-dark border-[1px] flex flex-col items-center gap-4 rounded-lg p-6">
            <div className="w-full p-4 sm:px-12 sm:py-14 rounded-lg bg-surface-light">
              <img
                src={LogitechKeyboard}
                className="w-full h-auto max-h-20"
                alt="Logitech Keyboard"
              />
            </div>
            <p className="text-lg font-medium text-onSurface dark:text-onSurface-dark">
              2nd Place
            </p>
            <p className="text-onSurface dark:text-onSurface-dark">Keyboard</p>
          </div>

          {/* 3rd Place */}
          <div className="bg-surfaceContain-light dark:bg-surfaceContain-dark border-border-light dark:border-border-dark border-[1px] flex flex-col items-center gap-4 rounded-lg p-6">
            <div className="w-full p-4 sm:px-16 sm:py-12 rounded-lg bg-surface-light">
              <img
                src={EchoDot}
                className="w-full h-auto max-h-24"
                alt="Echo Dot"
              />
            </div>
            <p className="text-lg font-medium text-onSurface dark:text-onSurface-dark">
              3rd Place
            </p>
            <p className="text-onSurface dark:text-onSurface-dark">Echo Dot</p>
          </div>
        </div>
      </div>

      {/* Special Prize Section */}
      <div className="flex flex-col sm:flex-row items-center justify-between gap-6 mt-8">
        <div className="flex flex-col gap-4">
          <p className="text-2xl text-onSurface-light dark:text-onSurface-dark">
            Special Prize!
          </p>
          <p className="text-onSurface-light dark:text-onSurface-dark">
            Kirby Plushie 💗
          </p>
        </div>
        <img src={Kirby} alt="Kirby" className="w-40 h-40 sm:w-72 sm:h-72" />
      </div>
    </div>
  );
};

export default Prizes;
