import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  materialLight,
  materialDark,
} from "react-syntax-highlighter/dist/esm/styles/prism";

const SubmissionCode = ({ code }) => {
  return (
    <div className="w-full px-4 py-0 rounded-lg border-[1px] border-border-light bg-surfaceContain-light border-bordrer-light dark:border-border-dark dark:bg-surfaceContain-dark">
      {/* Light Mode - Material Light Theme */}
      <div className="light:block dark:hidden">
        <SyntaxHighlighter
          language="python"
          style={materialLight}
          customStyle={{
            background: "none",
            fontSize: "0.875rem",
            color: "#2d2d2d",
          }}
          codeTagProps={{
            style: {
              background: "none",
            },
          }}
        >
          {code}
        </SyntaxHighlighter>
      </div>

      {/* Dark Mode - Material Dark Theme */}
      <div className="hidden dark:block">
        <SyntaxHighlighter
          language="python"
          style={materialDark}
          customStyle={{
            background: "none",
            fontSize: "0.875rem",
          }}
          codeTagProps={{
            style: {
              background: "none",
            },
          }}
        >
          {code}
        </SyntaxHighlighter>
      </div>
    </div>
  );
};

export default SubmissionCode;
