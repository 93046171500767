import React from "react";
import Winner from "../../Images/Brains/contest_winner.png";
import Pinata from "../../Images/Contests/boxPinata.png";
import HackMidwest from "../../Images/Contests/boxHackMidwest.png";
import { GamesInfo } from "../../Constants/Games";
import { useNavigate } from "react-router-dom";

const Contests = () => {
  const navigate = useNavigate();

  const contestInfo = [
    {
      title: "Pinata Chess Challenge",
      startDate: "2024-11-17T12:00:00",
      endDate: "2024-11-23T12:00:00",
      image: Pinata,
      game: GamesInfo.chess.image,
      navigate: "/contest/pinata",
    },
    {
      title: "HackMidwest Connect 4 Challenge",
      startDate: "2024-09-28T12:00:00",
      endDate: "2024-09-29T12:00:00",
      image: HackMidwest,
      game: GamesInfo.connect4.image,
      navigate: "/contest/hackmidwest",
    },
  ];

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  };

  const formatTime = (dateStr) => {
    const date = new Date(dateStr);
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: "America/Chicago",
    };
    return date.toLocaleTimeString("en-US", options) + " CST";
  };

  const getContestStatus = (startDate, endDate) => {
    const now = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (now < start) {
      return {
        date: formatDate(startDate),
        time: formatTime(startDate),
        status: "Upcoming",
      };
    } else if (now > end) {
      return {
        date: formatDate(endDate),
        time: formatTime(endDate),
        status: "Expired",
      };
    } else {
      return {
        date: formatDate(endDate),
        time: formatTime(endDate),
        status: "Active",
      };
    }
  };

  return (
    <div className="flex flex-col items-center">
      <div className="w-full bg-surfaceContain-light dark:bg-surfaceContain-dark text-center text-onSurface-light dark:text-onSurface-dark py-12 pb-32 flex flex-col items-center">
        <img className="w-40 pb-8" src={Winner} alt="Winner logo" />
        <div className="text-5xl font-bold pb-4">Contest</div>
        <div className="text-md font-medium break-words">
          Participate in contests to win prizes!
        </div>
      </div>
      {/* Adding negative margin to overlap the div above */}
      <div className="flex justify-center items-center pt-10 pb-20 -mt-32">
        <div className="flex flex-col sm:flex-row gap-8">
          {contestInfo.map((contest, index) => {
            const { date, time, status } = getContestStatus(
              contest.startDate,
              contest.endDate
            );
            return (
              <div
                key={index}
                onClick={() => navigate(contest.navigate)}
                className="bg-white shadow-lg rounded-lg text-center w-72 pb-6 transform transition-transform duration-200 hover:scale-105 cursor-pointer"
              >
                <div className="relative">
                  <img
                    className="w-full mx-auto rounded-t-lg"
                    src={contest.image}
                    alt={`${contest.title} logo`}
                  />
                  <img
                    className="absolute bottom-2 left-2 w-12 h-12 rounded-md"
                    src={contest.game}
                    alt="Game logo"
                  />
                </div>
                <div
                  className={`text-sm font-semibold py-1 rounded-b-lg 
                                    ${
                                      status === "Upcoming"
                                        ? "bg-yellow-200 text-yellow-800"
                                        : status === "Expired"
                                        ? "bg-gray-200 text-gray-800"
                                        : "bg-green-200 text-green-800"
                                    }
                                `}
                >
                  {status}
                </div>
                <div className="text-2xl font-semibold pt-5 px-6">
                  {contest.title}
                </div>
                <div className="text-gray-600 mt-2 px-6">{date}</div>
                <div className="text-gray-600 px-4">{time}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Contests;
