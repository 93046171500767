import React from "react";
import ErrorBrain from "../../Images/Brains/error.png";

function ErrorPage() {
  return (
    <div className="flex flex-col gap-4 items-center justify-center mt-20">
      <img src={ErrorBrain} alt="Loading" className="h-40" />
      <p className="text-lg font-semibold text-onSurface-light dark:text-onSurface-dark">
        An error occured!
      </p>
    </div>
  );
}

export default ErrorPage;
